<template>
<div>
    <b-row>

        <b-col md="6">
            <CCard>
                <CCardHeader color="primario" text-color="white">
                    <i class="fas fa-edit fa-mdr"></i> <span class="h5"> Estadísticas de registro de alimentos e insumos </span>
                </CCardHeader>
                <CCardBody>
                    <span class="h6 text-muted">ESTADÍSTICAS DEL SISTEMA PARA EL REGISTRO DE ALIMENTOS E INSUMOS:
                    </span>
                    <hr>
                    <b-row class="justify-content-center text-center">
                        <b-col cols="12" class=" my-2 icon-custom">
                            <i style="inline-size: auto" class="ico fas fa-utensils fa-3x pb-1"></i>
                            <br>
                            <span class="lg-numero">{{listaAlimentos.length}}</span>
                            <br>
                            <span class="text-muted">Registro de alimentos e insumos</span>
                        </b-col>
                    </b-row>
                </CCardBody>
            </CCard>
        </b-col>
        <b-col md="6">
            <CCard>
                <CCardHeader color="primario" text-color="white">
                    <i class="fas fa-cog"></i><span class="h5"> Gestion de Registro de alimentos e insumos</span>
                </CCardHeader>
                <CCardBody>
                    <b-row class="justify-content-center text-center">
                        <b-col cols="6" class="my-2">
                            <b-button block variant="custom" :to="{name:'Proyectos'}">
                                <i style="inline-size: auto" class="fas fa-arrow-left fa-3x pb-1"></i><br><span>Volver</span>
                            </b-button>
                        </b-col>
                        <b-col cols="6" class="my-2">
                            <b-button block variant="custom" @click="modalRegistrarAlimentos = true">
                                <i style="inline-size: auto" class="fas fa-plus-circle fa-3x pb-1"></i><br><span>Nuevo Registro de alimentos e insumos</span>
                            </b-button>
                        </b-col>
                    </b-row>
                </CCardBody>
            </CCard>
        </b-col>
        <b-col md="12">
            <CCard>
                <CCardHeader color="primario" text-color="white">
                    <i class="fas fa-list fa-md"></i><span class="h5">Registro de alimentos e insumos registrados </span>
                </CCardHeader>
                <CCardBody>
                    <b-row>
                        <b-col md="3">
                            <b-form-group label="Registros por página" class="text-muted">
                                <b-form-select size="xl" v-model="porPagina" :options="paginaOpciones"></b-form-select>
                            </b-form-group>
                        </b-col>
                        <b-col md="6">
                        </b-col>
                        <b-col md="3">
                            <b-form-group label="Busqueda:" class="text-muted">
                                <b-input-group size="xl">
                                    <b-input-group-prepend is-text>
                                        <i class="fas fa-search my-0 mx-0"></i>
                                    </b-input-group-prepend>
                                    <b-form-input type="search" v-model="filter" id="filterInput" placeholder="Buscar..."></b-form-input>
                                </b-input-group>
                            </b-form-group>
                        </b-col>
                        <b-col md="12">
                            <b-table bordered hover show-empty mediun responsive outlined :items="listaAlimentos" :fields="camposAlimentos" :current-page="paginaActual" :per-page="porPagina" :filter="filter" :filter-included-fields="filterOn" @filtered="onFiltered" empty-text="Aún no hay datos en esta sección." empty-filtered-text="No hay datos que coincidan con tu busqueda.">
                                <template v-slot:cell(index)="data">{{ data.index + 1 }}</template>
                                <template v-slot:cell(opciones)="param">
                                    <b-button size="sm" v-c-tooltip.hover.click="'Ver detalles'" @click="abrirmodalActualizarAlimento(param)" variant="dark" class=" mr-1 mb-1">
                                        <span class="btn-inner--icon">
                                            <i class="fas fa-search my-0 mx-0"></i>
                                        </span>
                                    </b-button>

                                    <b-button size="sm" class="mr-1 mb-1" v-c-tooltip.hover.click="'Eliminar'" @click="eliminarAlimentos(param)" variant="danger">
                                        <span class="btn-inner--icon">
                                            <i class="fas fa-trash-alt  my-0 mx-0"></i>
                                        </span>
                                    </b-button>
                                </template>
                                <template v-slot:cell(idProyecto)="param">
                                    {{comboProyecto.find(x => x.idProyecto==param.item.idProyecto).nombre}}
                                </template>
                                <template v-slot:cell(referencias)="data">{{ data.item.referencias | truncate(80)}}</template>

                            </b-table>
                        </b-col>
                    </b-row>
                </CCardBody>
                <CCardFooter>
                    <b-pagination size="sm" v-model="paginaActual" :total-rows="filasTotales" :per-page="porPagina" first-number last-number align="right" class="my-0"></b-pagination>
                </CCardfooter>
            </CCard>
        </b-col>
    </b-row>
    <CModal :show.sync="modalRegistrarAlimentos" size="xl" color="primario">
        <template #header>
            <h6 class="modal-title">
                <i class="fas fa-plus-square fa-md"></i>
                <span class="h5"> Nuevo Registro de Alimentos e insumos</span>
            </h6>
            <CButtonClose @click="modalRegistrarAlimentos = false" class="text-white" />
        </template>
        <validation-observer ref="observer" v-slot="{ handleSubmit }">
            <b-form @submit.stop.prevent="handleSubmit(registrarAlimentos)">
                <b-row>
                    <b-col md="12">
                        <validation-provider name="proyecto" rules="required" v-slot="{errors}">
                            <b-form-group label="Proyecto:" class="mb-2">
                                <v-select :reduce="comboProyecto =>comboProyecto.idProyecto" label="nombre" placeholder="Seleccione una opción" :class=" {'style-valid-select': datosAlimentos.idProyecto , 'style-invalid-select is-invalid': !!errors.length}" v-model="datosAlimentos.idProyecto" :options="comboProyecto" @search:blur="blurProyecto">
                                    <span slot="no-options" class="text-muted">No hay datos que mostrar. <router-link :to="{name:'Proyectos'}"> Registra aquí</router-link></span>
                                </v-select>
                                <div class="invalid-feedback">{{ errors[0]}}</div>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="6">
                        <validation-provider name="caminata" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="Caminata:" class="mb-2">
                                <b-form-input :state="getValidationState(validationContext)" placeholder="Ingrese la distancia recorrida" v-model="datosAlimentos.caminata"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="6">
                        <validation-provider name="fecha" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="Fecha:" class="mb-2">
                                <b-form-input v-model="datosAlimentos.fecha" type="date" :state="getValidationState(validationContext)"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="6">
                        <validation-provider name="cocinero" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="Cocinero:" class="mb-2">
                                <b-form-input :state="getValidationState(validationContext)" placeholder="Ingrese el cocinero" v-model="datosAlimentos.cocinero"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="6">
                        <validation-provider name="n° de pax" :rules="{required: true, numeric: true}" v-slot="validationContext">
                            <b-form-group label="Numero de Pax:" class="mb-2">
                                <b-form-input :state="getValidationState(validationContext)" placeholder="Ingrese el numero de pax" v-model="datosAlimentos.numero"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="12" class="mt-2">
                        <span class="h6 text-muted">Lista de Alimentos e insumos</span>
                        <CButton size="sm" class="float-right" color="dark" @click="agregarActividad()">
                            <i class="fas fa-plus fa-sm"></i> Agregar Alimentos e insumos
                        </CButton>
                    </b-col>
                    <b-col md="12" class="mt-1">
                        <b-table :items="datosAlimentos.listaAlimentos" :fields="cabeceraActividades" class="table-custom" fixed responsive show-empty mediun outlined hover empty-text="Aún no hay datos en esta sección.">
                            <!-- <template #table-colgroup="cabeceraActividades">
                                <col v-for="field in cabeceraActividades.fields" :key="field.key" :style="{ width: field.key === 'descripcion' ? '20%' : field.key === 'horaFin' ? '20%' : field.key === 'actividad' ? '50%' : '10%'}">
                            </template> -->
                            <template v-slot:cell(numero)="rowActividad">
                                <b-form-input placeholder="Ingrese el numero" v-model.lazy="rowActividad.item.numero"></b-form-input>
                            </template>
                            <template v-slot:cell(producto)="rowActividad">
                                <b-form-input placeholder="Ingrese el producto" v-model.lazy="rowActividad.item.producto"></b-form-input>
                            </template>
                            <template v-slot:cell(tipo)="rowActividad">
                                <b-form-input placeholder="Ingrese el tipo" v-model.lazy="rowActividad.item.tipo" rows="1" max-rows="1"></b-form-input>
                            </template>
                            <template v-slot:cell(cantidad)="rowActividad">
                                <b-form-input placeholder="Ingrese la cantidad" v-model.lazy="rowActividad.item.cantidad" rows="3" max-rows="6"></b-form-input>
                            </template>
                            <template v-slot:cell(kilo)="rowActividad">
                                <b-form-input placeholder="Ingrese el kilo" v-model.lazy="rowActividad.item.kilo" rows="3" max-rows="6"></b-form-input>
                            </template>
                            <template v-slot:cell(pu)="rowActividad">
                                <b-form-input placeholder="Ingrese el precio unitario" v-model.lazy="rowActividad.item.pu"></b-form-input>
                            </template>
                            <template v-slot:cell(observaciones)="rowActividad">
                                <b-form-input placeholder="Ingrese las observaciones" v-model.lazy="rowActividad.item.firma" rows="3" max-rows="6"></b-form-input>
                            </template>
                            <template v-slot:cell(transporteYEnvases)="rowActividad">
                                <b-form-input placeholder="Ingrese el transporte" v-model.lazy="rowActividad.item.transporteYEnvases" rows="3" max-rows="6"></b-form-input>
                            </template>
                            <template v-slot:cell(envasesReutilizables)="rowActividad">
                                <b-form-input placeholder="Ingrese los envases" v-model.lazy="rowActividad.item.envasesReutilizables" rows="3" max-rows="6"></b-form-input>
                            </template>

                               <template #cell(acciones)="param">
                                <b-button @click="quitarActividad(param)" v-if="datosAlimentos.listaAlimentos.length >= 2" class="mr-1" size="sm" variant="danger" v-c-tooltip="'Eliminar'">
                                    <i class="fas fa-trash-alt  my-0 mx-0"></i>
                                </b-button>
                            </template>
                        </b-table>
                    </b-col>

                    <b-col md="12" class="text-right my-2">
                        <b-button :disabled="disabled" variant="success" class=" mr-2" type="submit">
                            Guardar
                        </b-button>
                        <b-button variant="danger" @click="modalRegistrarAlimentos = false">
                            Cancelar
                        </b-button>
                    </b-col>
                </b-row>
            </b-form>
        </validation-observer>
        <template #footer>
            <span></span>
        </template>
    </CModal>

    <CModal :show.sync="modalActualizarAlimentos" size="xl" color="primario">
        <template #header>
            <h6 class="modal-title"> <i class="fas fa-edit fa-md"></i>
                <span class="h5"> Actualizar lote de productos</span></h6>
            <CButtonClose @click="modalActualizarAlimentos = false" class="text-white" />
        </template>
        <validation-observer ref="observer2" v-slot="{ handleSubmit }">
            <b-form @submit.stop.prevent="handleSubmit(actualizarAlimentos)">
                <b-row>
                    <b-col md="12">
                        <validation-provider name="proyecto" rules="required" v-slot="{errors}">
                            <b-form-group label="Proyecto:" class="mb-2">
                                <v-select :reduce="comboProyecto =>comboProyecto.idProyecto" label="nombre" placeholder="Seleccione una opción" :class=" {'style-valid-select': datosActualizarAlimentos.idProyecto , 'style-invalid-select is-invalid': !!errors.length}" v-model="datosAlimentos.idProyecto" :options="comboProyecto" @search:blur="blurProyecto">
                                    <span slot="no-options" class="text-muted">No hay datos que mostrar. <router-link :to="{name:'Proyectos'}"> Registra aquí</router-link></span>
                                </v-select>
                                <div class="invalid-feedback">{{ errors[0]}}</div>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="6">
                        <validation-provider name="caminata" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="Caminata:" class="mb-2">
                                <b-form-input :state="getValidationState(validationContext)" placeholder="Ingrese la distancia recorrida" v-model="datosActualizarAlimentos.caminata"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="6">
                        <validation-provider name="fecha" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="Fecha:" class="mb-2">
                                <b-form-input v-model="datosActualizarAlimentos.fecha" type="date" :state="getValidationState(validationContext)"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="6">
                        <validation-provider name="cocinero" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="Cocinero:" class="mb-2">
                                <b-form-input :state="getValidationState(validationContext)" placeholder="Ingrese el cocinero" v-model="datosActualizarAlimentos.cocinero"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="6">
                        <validation-provider name="n° de pax" :rules="{required: true, numeric: true}" v-slot="validationContext">
                            <b-form-group label="Numero de Pax:" class="mb-2">
                                <b-form-input :state="getValidationState(validationContext)" placeholder="Ingrese el numero de pax" v-model="datosActualizarAlimentos.numero"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="12" class="mt-2">
                        <span class="h6 text-muted">Lista de Alimentos e insumos</span>
                        <CButton size="sm" class="float-right" color="dark" @click="agregarActividad()">
                            <i class="fas fa-plus fa-sm"></i> Agregar Alimentos e Insumos
                        </CButton>
                    </b-col>
                    <b-col md="12" class="mt-2">
                        <b-table :items="datosAlimentos.listaAlimentos" :fields="cabeceraActividades" class="table-custom" fixed responsive show-empty mediun outlined hover empty-text="Aún no hay datos en esta sección.">
                            <!-- <template #table-colgroup="cabeceraActividades">
                                <col v-for="field in cabeceraActividades.fields" :key="field.key" :style="{ width: field.key === 'descripcion' ? '20%' : field.key === 'horaFin' ? '20%' : field.key === 'actividad' ? '50%' : '10%'}">
                            </template> -->
                           <template v-slot:cell(numero)="rowActividad">
                                <b-form-input placeholder="Ingrese el numero" v-model.lazy="rowActividad.item.numero"></b-form-input>
                            </template>
                            <template v-slot:cell(producto)="rowActividad">
                                <b-form-input placeholder="Ingrese el producto" v-model.lazy="rowActividad.item.producto"></b-form-input>
                            </template>
                            <template v-slot:cell(tipo)="rowActividad">
                                <b-form-input placeholder="Ingrese el tipo" v-model.lazy="rowActividad.item.tipo" rows="1" max-rows="1"></b-form-input>
                            </template>
                            <template v-slot:cell(cantidad)="rowActividad">
                                <b-form-input placeholder="Ingrese la cantidad" v-model.lazy="rowActividad.item.cantidad" rows="3" max-rows="6"></b-form-input>
                            </template>
                            <template v-slot:cell(kilo)="rowActividad">
                                <b-form-input placeholder="Ingrese el kilo" v-model.lazy="rowActividad.item.kilo" rows="3" max-rows="6"></b-form-input>
                            </template>
                            <template v-slot:cell(pu)="rowActividad">
                                <b-form-input placeholder="Ingrese el precio unitario" v-model.lazy="rowActividad.item.pu"></b-form-input>
                            </template>
                            <template v-slot:cell(observaciones)="rowActividad">
                                <b-form-input placeholder="Ingrese las observaciones" v-model.lazy="rowActividad.item.firma" rows="3" max-rows="6"></b-form-input>
                            </template>
                            <template v-slot:cell(transporteYEnvases)="rowActividad">
                                <b-form-input placeholder="Ingrese el transporte" v-model.lazy="rowActividad.item.transporteYEnvases" rows="3" max-rows="6"></b-form-input>
                            </template>
                            <template v-slot:cell(envasesReutilizables)="rowActividad">
                                <b-form-input placeholder="Ingrese los envases" v-model.lazy="rowActividad.item.envasesReutilizables" rows="3" max-rows="6"></b-form-input>
                            </template>

                            
                            <template #cell(acciones)="param">
                                <b-button @click="quitarActividad(param)" v-if="datosActualizarAlimentos.listaAlimentos.length >= 2" class="mr-1" size="sm" variant="danger" v-c-tooltip="'Eliminar'">
                                    <i class="fas fa-trash-alt  my-0 mx-0"></i>
                                </b-button>
                            </template>
                        </b-table>
                    </b-col>

                    <b-col lg="12" class="text-right my-2">
                        <b-button :disabled="disabled" variant="success" class=" mr-2" type="submit">
                            Guardar
                        </b-button>
                        <b-button variant="danger" @click="modalActualizarAlimentos= false">
                            Cancelar
                        </b-button>
                    </b-col>
                </b-row>
            </b-form>
        </validation-observer>
        <template #footer>
            <span></span>
        </template>
    </CModal>
</div>
</template>

<script>

import firebase from 'firebase';
const dbAlimentosEInsumos = firebase.firestore().collection('alimentosEInsumos');
const da = firebase.firestore().collection('proyectos');

export default {
    data() {
        return {
            disabled: false,
            listaAlimentos: [],
            camposAlimentos: [{
                    key: "index",
                    label: "N°",
                    class: "text-center",
                },
                {
                    key: "caminata",
                    label: "Caminata",
                    class: "text-center",
                },
                {
                    key: "cocinero",
                    label: "Cocinero",
                    class: "text-center",
                },
                {
                    key: "fecha",
                    label: "Fecha",
                    class: "text-center",
                },
                {
                    key: "numero",
                    label: "Numero de Pax",
                    class: "text-center",
                },
                  {
                    key: "opciones",
                    label: "",
                    class: "text-center",
                },

               
            ],
            cabeceraActividades: [{
                    key: 'numero',
                    label: 'N°',
                    class: 'text-center'
                }, {
                    key: 'producto',
                    label: 'Producto',
                    class: 'text-center'
                }, {
                    key: 'tipo',
                    label: 'Tipo',
                    class: 'text-center'
                }, {
                    key: 'cantidad',
                    label: 'Cantidad',
                    class: 'text-center'
                },
                {
                    key: 'kilo',
                    label: 'Kilo',
                    class: 'text-center'
                },
                {
                    key: 'pu',
                    label: 'Precio Unitario',
                    class: 'text-center'
                },
                {
                    key: 'observaciones',
                    label: 'Observaciones',
                    class: 'text-center'
                },
                {
                    key: "transporteYEnvases",
                    label: "Transporte y Envases",
                    class: "text-center",
                },
                {
                    key: "envasesReutilizables",
                    label: "Envases Reutilizables",
                    class: "text-center",
                },
                {
                    key: "acciones",
                    label: "",
                    class: "text-center",
                },
                

            ],

            comboProyecto: [],

            filasTotales: 1,
            paginaActual: 1,
            porPagina: 10,
            paginaOpciones: [10, 25, 50, 100, {
                value: 9999999,
                text: "Mostrar todos"
            }],

            filter: null,
            filterOn: [],

            datosAlimentos: {
                idCliente: '',
                idProyecto: '',
                caminata:'',
                fecha: moment().format('YYYY-MM-DD'),
                cocinero:'',
                numero:'',
                listaAlimentos: [{
                    numero: '',
                   producto: '',
                    tipo: '',
                    cantidad: '',
                    kilo: '',
                    pu: '',
                    observaciones: '',
                    transporteYEnvases:'',
                    envasesReutilizables:'',
                    acciones: '',
                }],

            },
            datosActualizarAlimentos: {
                idAlimentos: '',
                idCliente: '',
                listaAlimentos: [{
                    numero: '',
                   producto: '',
                    tipo: '',
                    cantidad: '',
                    kilo: '',
                    pu: '',
                    observaciones: '',
                    transporteYEnvases:'',
                    envasesReutilizables:'',
                    acciones: '',
                }],
            },

            modalRegistrarAlimentos: false,
            modalActualizarAlimentos: false,

        }
    },
    methods: {
        onFiltered(itemsFiltrados) {
            this.filasTotales = itemsFiltrados.length
            this.paginaActual = 1
        },
        getValidationState({
            dirty,
            validated,
            valid = null
        }) {
            return dirty || validated ? valid : null;
        },

        blurProyecto() {
            this.computedForm.refs.proyecto.validate();
        },
        blurProyectoActualizar() {
            this.computedActualizarForm.refs.proyecto.validate();
        },
        agregarActividad(k) {
            let me = this;
            me.datosAlimentos.listaAlimentos.push({
                numero: '',
                caminata: '',
                cocinero: '',
                numero: '',
                fecha: moment().format('YYYY-MM-DD'),
                
                acciones: '',

            })
        },
        quitarActividad(param) {
            let me = this;
            me.datosAlimentos.listaAlimentos.splice(param.index, 1);
        },
        /* agregarActividadActualizar(k) {
            let me = this;
            me.datosActualizarItinerario.listaDias[k].listaActividades.push({
                horaInicio: '',
                horaFin: '',
                actividad: ''
            })
        },
        quitarActividadActualizar(param, k) {
            let me = this;
            me.datosActualizarItinerario.listaDias[k].listaActividades.splice(param.index, 1);
        }, */
       listarProyectos() {
            let me = this;
            da
                .where("estado", "==", 2)
                .where('idCliente', '==', String(me.datosAlimentos.idCliente))
                .onSnapshot((querySnapshot) => {
                    me.comboProyecto = [];
                    querySnapshot.forEach((doc) => {
                        me.comboProyecto.push({
                            idProyecto: doc.id,
                            nombre: doc.data().nombre,
                            fechaCreacion: doc.data().fechaCreacion,
                            fechaModificacion: doc.data().fechaModificacion,
                        });
                    });
                    me.comboProyecto.sort((a, b) => (a.fechaCreacion > b.fechaCreacion) ? -1 : ((b.fechaCreacion > a.fechaCreacion) ? 1 : 0));

                });
        },
        agregarAlimentos() {
            let me = this;
            me.datosAlimentos.listaAlimentos.push({
                caminata: '',
                cocinero: '',
                numero: '',
                fecha: moment().format('YYYY-MM-DD'),
               
                acciones: '',
            })
        },

        listarAlimentos() {
            let me = this;
            console.log(me.listaAlimentos)
            dbAlimentosEInsumos
                .where("estado", "==", 2)
                .where('idCliente', '==', String(me.datosAlimentos.idCliente))
                .onSnapshot((querySnapshot) => {
                    me.listaAlimentos = [];
                    querySnapshot.forEach((doc) => {
                        me.listaAlimentos.push({
                            idAlimentos: doc.id,
                            caminata: doc.data().caminata,
                            cocinero: doc.data().cocinero,
                            idCliente: doc.data().idCliente,
                            numero: doc.data().numero,
                            
                            fecha: moment(doc.data().fecha).format('YYYY-MM-DD'),
                            

                        });
                    });
                    me.listaAlimentos.sort((a, b) => (a.fechaCreacion > b.fechaCreacion) ? -1 : ((b.fechaCreacion > a.fechaCreacion) ? 1 : 0))
                });
        },
      

        registrarAlimentos() {
            let me = this;
            me.disabled = true;
            dbAlimentosEInsumos.add({
                    idCliente: me.datosAlimentos.idCliente,
                    idProyecto: me.datosAlimentos.idProyecto,
                    caminata: me.datosAlimentos.caminata,
                    cocinero: me.datosAlimentos.cocinero,
                    numero: me.datosAlimentos.numero,
                    fecha: me.datosAlimentos.fecha,
                    listaAlimentos: me.datosAlimentos.listaAlimentos,
                     estado: 2,
                    fechaCreacion: firebase.firestore.Timestamp.fromDate(new Date()),
                    fechaModificacion: firebase.firestore.Timestamp.fromDate(new Date()),
                })
                .then((docRef) => {
                    // console.log("Creado correctamente ID: ", docRef.id);
                    me.swat('success', 'Registrado satisfactoriamente')
                    me.modalRegistrarAlimentos = false
                    me.disabled = false;
                })
                .catch((error) => {
                    me.swat('error', 'Algo salió mal!')
                });

        },
        actualizarAlimentos() {
            let me = this;
            me.disabled = true;
            dbAlimentosEInsumos.doc(me.datosActualizarAlimentos.idAlimentos).update({
                    caminata: me.datosActualizarAlimentos.caminata,
                    fecha: me.datosActualizarAlimentos.fecha,
                    cocinero: me.datosActualizarAlimentos.cocinero,
                    numero: me.datosActualizarAlimentos.numero,
                    
                    fechaModificacion: firebase.firestore.Timestamp.fromDate(new Date()),
                })
                .then(() => {
                    //console.log("Actualizado correctamente");
                    me.swat('success', 'Modificado satisfactoriamente')
                    me.modalActualizarAlimentos = false
                    me.disabled = false;
                })
                .catch((error) => {
                    me.disabled = false;
                    me.swat('error', 'Algo salió mal!')
                });

        },
        eliminarAlimentos(param) {
            let me = this
            me.$swal.fire({
                title: '¿Estas seguro de eliminar el guía?',
                text: "¡No podrás revertir esto!",
                icon: 'warning',
                showCancelButton: false,
                confirmButtonColor: '#d33',
                confirmButtonText: 'Eliminar',
            }).then((result) => {
                if (result.isConfirmed) {
                    dbAlimentosEInsumos.doc(param.item.idAlimentos).update({
                            estado: 1,
                            fechaModificacion: firebase.firestore.Timestamp.fromDate(new Date()),
                        })
                        .then(() => {
                            me.swat('success', 'Eliminado satisfactoriamente')
                            me.disabled = false;
                        })
                        .catch((error) => {
                            me.disabled = false;
                            me.swat('error', 'Algo salió mal!')
                        });

                }
            })
        },
        abrirmodalActualizarAlimento(param) {
            this.datosActualizarAlimentos.idAlimentos = param.item.idAlimentos;
            this.datosActualizarAlimentos.caminata = param.item.caminata;
            this.datosActualizarAlimentos.fecha = param.item.fecha;
            this.datosActualizarAlimentos.cocinero = param.item.cocinero;
            this.datosActualizarAlimentos.numero = param.item.numero;
            
            this.datosActualizarAlimentos.acciones = param.item.acciones;

            this.modalActualizarAlimentos = true
        },

        swat(icon, title) {
            this.$swal.mixin({
                toast: true,
                showConfirmButton: false,
                position: 'bottom-right',
                timer: 3500,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener('mouseenter', this.$swal.stopTimer)
                    toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                }
            }).fire({
                icon: icon,
                title: title
            });
        },
        resetmodalRegistrarAlimentos() {
            this.$nextTick(() => {
                this.$refs.observer.reset();
            });
            
            this.datosAlimentos.caminata = '';
            this.datosAlimentos.fecha = moment().format('YYYY-MM-DD');
            this.datosAlimentos.cocinero = '';
            this.datosAlimentos.acciones = '';
            this.datosAlimentos.acciones = '';
         

        },
        resetmodalActualizarAlimentos() {
            this.$nextTick(() => {
                this.$refs.observer2.reset();
            });
        },
    },
    computed: {
        computedForm() {
            return this.$refs.observer;
        },
        computedFormActualizar() {
            return this.$refs.observer2;
        },

    },
    watch: {
        modalRegistrarAlimentos: function (val) {
            if (val == false) {
                this.resetmodalRegistrarAlimentos();
            }
        },
        modalActualizarAlimentos: function (val) {
            if (val == false) {
                this.resetmodalActualizarAlimentos();
            }
        }
    },
    mounted() {
        if (this.$store.state.user) {
            let user = this.$store.state.user;
            this.datosAlimentos.idCliente = this.datosActualizarAlimentos.idCliente = user.idCliente;
            this.listarAlimentos();
            this.listarProyectos();
           
        }
    }

}
</script>
